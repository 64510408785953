





























































































































































import { Component, Watch, Vue } from "vue-property-decorator";
import { getModule } from "vuex-module-decorators";
import submission from "@/store/modules/submission";

@Component
export default class SignUpForm extends Vue {
  googlePlayEmail: string | null = null;
  hasAndroid = false;
  hasApple = false;
  androidValidationError: string | null = null;
  noneSelectedValidationError: string | null = null;
  submissionModule!: submission;
  processing = false;
  
  get disableTestFlightSignUps(): boolean {
    const disableTestFlight: string | undefined = process.env.VUE_APP_DISABLE_TESTFLIGHT_SIGN_UPS
    if (disableTestFlight == undefined || disableTestFlight.toLocaleLowerCase() === 'false') {
      return false
    }
    return true
  }

  get submissionErrorMessage(): string | null {
    return this.submissionModule.errorMessage;
  }

  get isValid(): boolean {
    const oneSelected = this.hasAndroid || this.hasApple;
    if (this.hasAndroid && !this.validateEmail(this.googlePlayEmail)) {
      return false;
    }
    if (!oneSelected) {
      return false;
    }
    return true;
  }

  get submitDisabled(): boolean {
    if (!this.isValid) {
      return true;
    }
    return this.processing;
  }

  checkForm(): boolean {
    this.androidValidationError = null;
    this.noneSelectedValidationError = null;
    if (this.isValid) {
      return true;
    }
    const oneSelected = this.hasAndroid || this.hasApple;
    if (this.hasAndroid && !this.validateEmail(this.googlePlayEmail)) {
      this.androidValidationError =
        "A valid Google Play email address is required to sign up for the Android beta";
    }
    if (!oneSelected) {
      this.noneSelectedValidationError =
        "At least one of the platforms must be selected.";
    }
    return false;
  }

  private validateEmail(email: string | null): boolean {
    //Very lightweight email validation routine.
    if (email == null) return false;
    if (email.length < 4) return false;
    const atPos = email.indexOf("@");
    if (atPos < 1) return false;
    const domainDotPos = email.indexOf(".", atPos + 1);
    if (domainDotPos < 0) return false; //Needs a period after the @
    if (domainDotPos == email.length) return false; //Period can't be the last character of the email.
    return true;
  }

  //Clear the submission error state on change.
  @Watch("hasAndroid")
  onHasAndroidChanged(val: boolean, oldVal: boolean) {
    this.submissionModule.clear();
  }

  //Clear the submission error state on change.
  @Watch("hasApple")
  onHasAppleChanged(val: boolean, oldVal: boolean) {
    this.submissionModule.clear();
  }

  handleSubmit() {
    if (!this.checkForm()) {
      return;
    }
    this.processing = true;
    this.submissionModule
      .submitRequest({
        vue: this,
        signUpIos: this.hasApple,
        signUpAndroid: this.hasAndroid,
        googlePlayEmail: this.googlePlayEmail,
      })
      .then((successful) => {
        this.processing = false;
        if (successful === true) {
          this.$router.replace("/signup/success");
        }
      });
    return false;
  }

  created() {
    this.submissionModule = getModule(submission, this.$store);
  }
}
