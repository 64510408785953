











import { Component, Vue } from "vue-property-decorator";
import { getModule } from "vuex-module-decorators";
import account from "@/store/modules/account";

import SignUpForm from "@/components/SignUpForm.vue";
import NotAvailable from "@/components/NotAvailable.vue";

@Component({
  components: {
    SignUpForm,
    NotAvailable,
  },
})
export default class SignUp extends Vue {
  accountModule!: account;
  get isProUser(): boolean {
    const reqPro: string | undefined = process.env.VUE_APP_REQUIRE_PRO;
    if (reqPro != undefined && reqPro.toLowerCase() === "false") {
      return true;
    }
    return this.accountModule.isProUser;
  }
  created() {
    this.accountModule = getModule(account, this.$store);
  }
}
